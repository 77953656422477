// No services yet! (we might want to at this stage already)

import {CerxesLoader} from "./components/view/cerxes-loader";
// import {render} from "@cerxes/csx";
import {render, CustomElement, defineElement, state, Host} from "@cerxes/csx";
import bootstrapStyle from "./bootstrap.pcss";

@defineElement("bootstrap-loader")
class BootstrapLoader extends CustomElement{
    @state()
    loaded = false;
    
    /** @type {CerxesLoader} */
    #loader;
    #loading = true;
    #animateOutDuration = 500;
    
    async start(){
        let start = new Date();
        let loader = this.#loader.play();
        
        await Promise.all([
            import("./main.js").then(m=>m.startApp())
        ]);
        
        let loadEnd = new Date();
        let duration = loadEnd.getTime()-start.getTime();
        let fadeOut = this.#animateOutDuration;
        console.log("loaded in " + duration + 'millis');
        
        if(duration > 500) {// If the browser clearly had to load something instead of just fetching for cache (500ms is just a magical value determined by testing)
            await this.#loader.fastForward(500);// Once loaded, fast forward the loader to stop after .5s
        }else{
            // Otherwise we're not waiting for the animation to finish
            fadeOut = 100;
        }
        
        this.loaded = true;
        this.update();
        await new Promise((resolve)=>setTimeout(()=>resolve(), fadeOut));
        document.body.removeChild(this); // remove self!
    }
    
    render(){
        let dots = [100,200,300]
        
        return <Host>
            <style>
                {bootstrapStyle}
            </style>
            
            { this.#loading? (
                <CerxesLoader
                    duration={3000}
                    ref={el=>this.#loader = el}
                    style={
                        this.loaded
                            ? {
                                animation: `slideOut ${this.#animateOutDuration}ms forwards`
                            }
                            : {
                            }
                    }
                >
                    <span className={"bootstraploader__loading-text"}>
                        Loading
                        {dots.map(delay=>(
                            <span
                                className={{
                                'bootstraploader__loading-dot': true,
                                'bootstraploader__loading-dot--loading': !this.loaded,
                            }}
                                style={{
                                    animationDelay: `${delay}ms`
                                }}
                            >
                                .
                            </span>
                        ))}
                    </span>
                </CerxesLoader>
            ): null}
        </Host>
        
    }
    
    connectedCallback() {
        super.connectedCallback();
    
        this.start();
    }
}


// IIFE
(async function(){
    let loader = render(
        <BootstrapLoader />
    );
    document.body.appendChild(loader);// we might need more style here?
})()
